import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from 'react-router-dom';

import AuthService from "../api/auth"
import "./../assets/login.css";



export default function CardLogin() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // set min length for email and password
    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    // Monitoring of formatting such as email with @
    function handleSubmit(event) {
        event.preventDefault();
    }

    // onClick function from login Button
    function useEffect(){

        // send email and password for acces_token
        AuthService.login(email, password)
    }

    // test is registrier ok for 
    //URL:http://127.0.0.1:5000/auth/protected
    /*function useEffectPython(){
        AuthService.register()
    }*/

    return(
        <div className="containerLogin" >
            <div className="cardLogin">
                <h2 className="cardLoginh2">Login</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="formLoginEmail" size="lg" controlId="email">
                        <Form.Control 
                            autoFocus
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="formLoginPassword" size="lg" controlId="password">
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <table className="tableLogin">
                        <tbody>
                            <tr className="buttonLogin">
                                <Button 
                                    block
                                    size="lg" 
                                    type="submit" 
                                    disabled={!validateForm()} 
                                    onClick={useEffect} 
                                >
                                Login
                                </Button>
                            </tr>
                            <tr>
                                <nav>
                                    <Link className='linkLogin' to="/registrier">Noch kein account Register</Link>
                                </nav>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            </div>
        </div>
    );
}