import React, { Component } from "react";
import { Card,} from "react-bootstrap";
import NavigationBar from "../routes/navigationsbar"

import "./../assets/home.css"


export default class Home extends Component {


    render(){
        return(
            <body>
                <div class="navBar">
                    <NavigationBar/>
                </div>
                <div class="bootCard">
                    <Card>
                        <Card.Img
                            variant="top"
                            src="Microchip-FINAL.webp"
                            />
                        <Card.Header className="cardHeader"><b>Einleitung:</b></Card.Header>
                        <Card.Body className="cardBody">
                            Aktuell passiert hier noch nichts, diese Seite ist im Aufbau.<br></br>
                            Später können Sie mit hilfe eines Mikrokontrollers<br></br>
                            etwas bauen, bis dahin bitte ich Sie um etwas Gedult.
                        </Card.Body>
                    </Card>
                </div>
            </body>
        );
    }
}