import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button,} from "react-bootstrap";
import axios from "axios";

/*import LoaderButton from "../components/loaderButton";*/
import { useFormFields } from "./../utils/hooks/hooksLib";
import "./../assets/regestrier.css";

export default function Registrier() {
  const [fields, handleFieldChange] = useFormFields({
    username: "",
    email: "",
    password: "",
    confirmPassword: ""
  });

  // setzt die Constanten
  const [setNewUser] = useState(false);

  /*const { userHasAuthenticated } = useAppContext();*/
  const [isLoading, setIsLoading] = useState(false);

  // bestimmt wie groß die Angaben sein müssen
  function validateForm() {
    return (
      fields.username.length > 0 &&
      fields.email.length > 0 &&
      fields.email.includes("@") &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }
  
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);


    setIsLoading(false);
  }
  

  // onClick function from signup Button
  //const API_URL_LOGIN = "http://127.0.0.1:5000/authRegistrier/newUser";
  const API_URL_LOGIN = "http://0.0.0.0:5000/authRegistrier/newUser";


  // sendet die Angaben vom Regestrieren an das Backend
  function useEffectRegestrie(){
    console.log("hallo test ok")
    // connect to url and send email and password
    axios.post(API_URL_LOGIN,{
      username: fields.username,
      email: fields.email,
      password: fields.password
    })

    // if answare data= "E-mail exist than..."
    /*.then(function(response){
      if (response.data === "E-Mail already exists") {
        console.log("E-Mail already exists")
        // setNewUser(false) und leitet somit nicht weiter
        setNewUser(false)
        
      }
      else{
        // renderConfirmationForm
        console.log("Registration successful. Check your email for verifications email")
        window.location.href = "http://localhost:3000/verify"
      }
    })
    */
  }

  // Rendert das Regestrations Fenster

  return (
    <div className="containerRegister">
      <div className="cardRegister">
        <h2 className="cardRegisterh2">Sing In</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="formRegisterUsername" controlId="username" size="lg">
            <Form.Control
              autoFocus
              type="username"
              placeholder="Username"
              value={fields.username}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group className="formRegisterEmail" controlId="email" size="lg">
            <Form.Control
              autoFocus
              type="email"
              placeholder="Email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group className="formRegisterPassword" controlId="password" size="lg">
            <Form.Control
              type="password"
              placeholder="Password"
              value={fields.password}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group className="formRegisterConfirm" controlId="confirmPassword" size="lg">
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              onChange={handleFieldChange}
              value={fields.confirmPassword}
            />
          </Form.Group>
          <table className="tableRegister">
            <tbody>
              <tr className="buttonRegister">
                <Button
                  block 
                  size="lg"
                  type="submit"
                  variant="primary"
                  isLoading={isLoading}
                  disabled={!validateForm()} 
                  onClick={useEffectRegestrie}
                >
                Signup
                </Button>
              </tr>
            </tbody>
          </table>
        </Form>
      </div>
    </div>
  );
}