import axios from "axios";

// This file is responsible for the login and log out functions

const API_URL_LOGIN = "http://127.0.0.1:5000/auth/";

class AuthService {

  // send all Infos for create jwt accesstoken
  login(email, password) {
    console.log("login as",email, password)

    // login sends email and password to the login api
    return axios.post(API_URL_LOGIN + "userLogin", {
      email: email,
      password: password
    // The result of this is the access token from the backend
    }).then(
      res => {
        if (res.data.token && res.data.user_id) {
          localStorage.setItem("jwt-token", res.data.token);
          localStorage.setItem("user-id", res.data.user_id);
          console.log(localStorage)
        }
        return (
          res.data,
          window.location.href = "http://127.0.0.1:3000/"
          );
      // if error than console log error
      }).catch(err => console.log(err));
  }

  // logout, register & getCurrentUser noch nicht getestet.
  logout() {
    localStorage.removeItem("user-id","jwt-token");
  }

  // send the create jwt accesstoken form Backend
  // on URL for chek is registered
  async register() {
    const resp = await fetch(`http://127.0.0.1:5000/auth/protected`, {
      // the Backend need this vor lock is user registered
      method: 'GET',
      headers: { 
        "Content-Type": "application/json",
        'Authorization': 'Bearer '+localStorage.getItem('jwt-token'),
      },
    })

    // awaits the answer from json
    const data = await resp.json();
    console.log("This is the data you requested:", data);
    return data
  }
  
  // is not needed at the moment
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}


export default new AuthService();