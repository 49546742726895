import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import Home from './pages/home';
import Registrier from './pages/regestrier';
import Login from './pages/login'



function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registrier" element={<Registrier />} />
        </Routes>   
    </BrowserRouter>
  );
}

export default App;