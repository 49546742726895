import React from 'react';
import { Link } from 'react-router-dom';

import "./../assets/navigationsbar.css";

const NavigationBar = () => {
  return (
    <div className='navbar-container'>
        <nav>
        <ul>
            <Link className='link' to="/registrier">Registrier</Link>
            <Link className='link' to="/login">Login</Link>
        </ul>
        </nav>
    </div>
  );
};

export default NavigationBar;